import { useDecision } from '@optimizely/react-sdk';
import { useEffect } from 'react';
import { useAtomValue } from 'jotai';

import { useCustomMixpanel } from '@services/Mixpanel';
import { DynamicUserProps } from '@services/optimizely';
import { log } from '@src/logging/logger';
import { excessAtom, selectedCoverTypeAtom, testUserAtom } from '@src/store/store';

type ABTestProps = {
  experimentName: string;
  accountId?: string;
  children: childrenRenderFunction;
};

type childrenRenderFunction = (flagEnabled: boolean, variation: string | null) => React.ReactNode;

const ABTest = ({ experimentName, accountId, children }: ABTestProps) => {
  const [decision] = useDecision(experimentName, {
    autoUpdate: true,
  });
  const { people } = useCustomMixpanel();

  const selectedCoverType = useAtomValue(selectedCoverTypeAtom);
  const excess = useAtomValue(excessAtom);
  const testUser = useAtomValue(testUserAtom);

  useEffect(() => {
    const dynamicProps: DynamicUserProps = {
      accountId: accountId ?? 'unknown',
      coverType: selectedCoverType ?? 'not set',
      excessLevel: excess,
      testUser: testUser ?? false,
    };
    if (decision.enabled && decision.variationKey) {
      log(`[ABTEST] - [${experimentName}] - feature flag enabled`, {
        experimentName,
        variationKey: decision.variationKey,
        flagEnabled: decision.enabled,
        ...dynamicProps,
      });
      people.set(experimentName, decision.variationKey);
    } else {
      log(`[ABTEST] - [${experimentName}] - feature flag disabled`, {
        experimentName,
        variationKey: decision.variationKey,
        flagEnabled: decision.enabled,
        ...dynamicProps,
      });
    }
  }, [
    accountId,
    decision.enabled,
    decision.variationKey,
    excess,
    experimentName,
    people,
    selectedCoverType,
    testUser,
  ]);

  return (
    <div data-testid={`${experimentName}-experiment-${decision.variationKey}`}>
      {children(decision.enabled, decision.variationKey)}
    </div>
  );
};

export default ABTest;
