import styled from 'styled-components';
import { useAtomValue } from 'jotai';

import { InfoIcon } from '@components/Info';
import { useModalContext } from '@contexts/ModalContext';
import { SummaryItem } from '@entities/types';
import { stripBullet } from '@fixtures/summaryItems';
import { excessAtom } from '@src/store/store';

interface Props {
  item: SummaryItem;
  iconName?: string;
}

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  display: inline-flex;
`;

export const FindOutMore = ({ item, iconName = 'info-dark' }: Props) => {
  const { showModal } = useModalContext();
  const excess = useAtomValue(excessAtom);

  return (
    <StyledInfoIcon
      name={iconName}
      size={16}
      data-testid={`find-out-more-${item.id}`}
      onClick={() => {
        showModal({
          id: item.id,
          title: item.name,
          details: item.info[excess ? 'withExcess' : 'noExcess'].map(stripBullet),
        });
      }}
    />
  );
};
