import { Heading1, theme } from '@ovotech/element';
import styled, { css } from 'styled-components';
import { forwardRef } from 'react';

import { RepairCard } from './RepairCard';

import { mqMediumDown } from '@utils/mediaQuery';
import { Quote } from '@entities/types';
import { homeRecoverCoveredItems } from '@fixtures/summaryItems';
import { usePromoComponents } from '@hooks/usePromoComponents';
import { getPercentText } from '@utils/getPercentText';
import { CoveredItems } from '@components/CoveredItems';
import { LOGGED_IN_REPAIR_COST } from '@constants/homeRepair';

const StyledHomeRecoverPlanSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(2 * ${theme.core.space[15]}) ${theme.core.space[3]};

  ${mqMediumDown(`
    padding: 0 0;
    padding: 88px 0 80px 0};
  `)};
`;

const StyledHeading1 = styled(Heading1)`
  font-size: 64px;
  line-height: 95%;
  padding: ${theme.core.space[0]} ${theme.core.space[3]} ${theme.core.space[12]};
`;

const RepairCardsContainer = styled.div<{ isSingleCard?: boolean }>(
  ({ theme, isSingleCard }) => css`
    display: grid;
    grid-template-columns: repeat(${isSingleCard ? 1 : 2}, 1fr);
    gap: ${theme.core.space[10]};
    padding: 0 ${theme.core.space[3]};
    max-width: 806px;

    ${mqMediumDown(`
        padding-top: ${theme.core.space[8]};
        gap: ${theme.core.space[8]};
        grid-template-columns: 1fr;
        width: 100%;
      `)}
  `
);

type HomeRecoverPlanSectionProps = {
  isLoggedIn: boolean;
  repairCost: number;
  onContinue: () => void;
  quoteComplete: Quote;
};

export const HomeRecoverPlanSection = forwardRef<HTMLDivElement, HomeRecoverPlanSectionProps>(
  ({ isLoggedIn, repairCost, onContinue, quoteComplete }, ref) => {
    const { getHomeRecoverRepairCardContainer } = usePromoComponents();

    return (
      <>
        <StyledHomeRecoverPlanSection ref={ref}>
          <StyledHeading1>
            {quoteComplete.discountApplied ? (
              <>
                Home Recover <br />
                {getPercentText(quoteComplete.promotions[0].value)} offer
              </>
            ) : (
              <>Home Recover plans</>
            )}
          </StyledHeading1>
          {!quoteComplete.discountApplied && (
            <RepairCardsContainer isSingleCard={isLoggedIn}>
              <RepairCard
                cardVariant="existingCustomer"
                isLoggedIn={isLoggedIn}
                repairCost={LOGGED_IN_REPAIR_COST}
                onContinue={onContinue}
                quoteComplete={quoteComplete}
              />
              {!isLoggedIn && (
                <RepairCard
                  cardVariant="newCustomer"
                  isLoggedIn={isLoggedIn}
                  repairCost={repairCost}
                  onContinue={onContinue}
                  quoteComplete={quoteComplete}
                />
              )}
            </RepairCardsContainer>
          )}
          {quoteComplete.discountApplied &&
            getHomeRecoverRepairCardContainer(quoteComplete, quoteComplete.promotions, {
              onContinue,
            })}
        </StyledHomeRecoverPlanSection>
        <CoveredItems coveredItemsData={homeRecoverCoveredItems} />
      </>
    );
  }
);

HomeRecoverPlanSection.displayName = 'HomeRecoverPlanSection';
